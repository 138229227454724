import { createSelector } from '@ngrx/store';
import { getReferencesState } from '../reducers/state';
import * as fromReferences from './../reducers/references.reducer';


export const getOrganizationTypes = createSelector(
  getReferencesState,
  (state: fromReferences.ReferenceState) => state.organization_types.list
);

export const hasOrganizationTypesLoaded = createSelector(
  getReferencesState,
  (state: fromReferences.ReferenceState) => state?.organization_types && state?.organization_types?.loaded
);

export const referencesQuery = {
  getOrganizationTypes,
  hasOrganizationTypesLoaded
};
